<template>
  <transition name="fade" mode="out-in">
    <div
      id="main"
      :class="
        settings.graphics && settings.graphics.mode == 'low'
          ? 'low_graphics'
          : null
      "
    >
      <v-app id="main-app">
        <v-main>
          <v-container
            style="
              max-width: 480px;
              padding: 0;
              height: 100%;
              padding-top: 10px;
            "
          >
            <v-card class="mx-3" :class="
        settings.graphics && settings.graphics.mode == 'low'
          ? 'low_graphics'
          : null
      ">
              <v-img
                :max-height="
                  $route.name == 'PageEntranceMain' ? 200 : 45
                "
                max-width="100%"
                :src="require('@/assets/entrance/ScreenLogin.png')"
                :lazy-src="require('@/assets/entrance/LazyScreenLogin.png')"
                class="text-right pt-1"
              >
                <Setting
                  class="white px-5 py-7 rounded-circle d-inline"
                  style="margin-right: -10px"
                ></Setting>
              </v-img>
            </v-card>
            <transition name="bounce" mode="out-in">
              <router-view :key="$route.fullPath"></router-view>
            </transition>
          </v-container>
        </v-main>
        <DebugTool v-if="settings.debug"></DebugTool>
      </v-app>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import SettingSkeleton from "@/components/skeleton/Setting";
export default {
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  components: {
    Setting: () => ({
      component: import(
        /* webpackChunkName: "component-setting" */ "@/components/Setting"
      ),
      loading: SettingSkeleton,
      delay: 200,
    }),
    DebugTool: () => import(
    /* webpackChunkName: "component-debug-tool" */ "@/components/DebugTool"),
  },
  data: () => ({
    //
  }),
};
</script>

<style>
@media (max-height: 500px) {
  #main {
    background-size: auto 500px !important; /* Force the image to its minimum width */
  }
}

#main {
  background: url("../assets/background/BGEntrance.png") center top;
  background-size: cover !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#main-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.back-plot {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.low_plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.low_graphics {
  background: none !important;
}

.low_island_plot {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px;
  margin: 0 10px;
  /* padding: 20px !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_title_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  padding: 5px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 20px;
}
.low_island_building_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_coin_plot {
  background-color: rgb(246, 246, 246) !important;
  width: 100%;
  margin-right: 10px;
  padding: 5px 2%;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.low_island_name_plot {
  background-color: rgb(246, 246, 246) !important;
  width: calc(100% - 20px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 0 10px;
  padding: 5px 2%;
  border-radius: 10px;
}
</style>